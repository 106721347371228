<template>
  <div class="app-container">
    <div class="page-title" style="display:flex; flex-direction:row">
      <h2>{{ $t('groupement.groupements') }}</h2>
      <h2>👥</h2>
    </div>
    <el-row :gutter="40" class="panel-group">
      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
        <div class="card-panel">
          <div class="card-panel-icon-wrapper icon-people">
            <h1>👥</h1>
          </div>
          <div class="card-panel-description">
            <div class="card-panel-text">
              Total Groupements
            </div>
            <count-to :start-val="0" :end-val="total" :duration="2600" class="card-panel-num" />
          </div>
        </div>
      </el-col>
    </el-row>
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <span>{{ $t('general.filter') }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
      </div>
      <div class="filter-container">
        <el-radio v-model="activeList" label="desactives" border @change="getSubGroupementsList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
        <el-radio v-model="activeList" label="disponibles" border @change="handleFilterGroupements">{{ $t('general.disponibles') }}</el-radio>
        <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubGroupementsList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
      </div>
      <hr>
      <div style="display:flex;flex-direction:row;align-items:center; margin:20px">
        <el-tag>Filtres par Hiérarchie : </el-tag>
        <el-breadcrumb style="margin-left:10px" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item v-if="zoneFilter!==null"><el-tag type="success">{{ zones[zoneFilter].nom }}</el-tag></el-breadcrumb-item>
          <el-breadcrumb-item v-if="communeFilter!==null"><el-tag type="info">{{ zones[zoneFilter].communes[communeFilter].nom }}</el-tag></el-breadcrumb-item>
          <el-breadcrumb-item v-if="villageFilter!==null"><el-tag type="warning">{{ nomVillages[villageFilter] }}</el-tag></el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div style="display:flex; flex-direction:row">
        <el-select
          v-model="zoneFilter"
          placeholder="Zone"
          style="margin-right:10px; width:200px"
          @change="filterByZone(zoneFilter)"
        >
          <el-option
            v-for="(item, index) in zones"
            :key="index"
            :label="item.nom"
            :value="index"
          />
        </el-select>
        <el-select v-if="zoneFilter!==null" v-model="communeFilter" placeholder="Commune" style="margin-right:10px; width:200px" @change="filterByCommune(communeFilter)">
          <el-option
            v-for="(item, index) in zones[zoneFilter].communes"
            :key="item.id"
            :label="item.nom"
            :value="index"
          />
        </el-select>
        <el-select v-if="communeFilter!==null" v-model="villageFilter" placeholder="Village" style="margin-right:10px; width:200px" @change="filterByVillage(villageFilter)">
          <el-option
            v-for="item in zones[zoneFilter].communes[communeFilter].villages"
            :key="item.id"
            :label="item.nom"
            :value="item.id"
          />
        </el-select>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @input="handleFilterGroupements" />
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterGroupements">
          {{ $t('general.search') }}
        </el-button>
        <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button>
        <el-button v-if="checkPermission(['creer groupement'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handlecreateGroupement">
          {{ $t('general.add') }}
        </el-button>
        <!-- <el-select
          v-model="zoneFilter"
          placeholder="Zone"
          style="margin-right:10px; width:200px"
          @change="filterByZone(value)"
        >
          <el-option
            v-for="item in zones"
            :key="item.value"
            :label="item.nom"
            :value="item.id"
          />
        </el-select>
        <el-select
          v-if="zoneFilter!==null"
          v-model="communeFilter"
          placeholder="Commune"
          style="margin-right:10px; width:200px"
          @change="filterByCommune(value)"
        >
          <el-option
            v-for="item in communes"
            :key="item.id"
            :label="item.nom"
            :value="item.id"
          />
        </el-select>
        <el-select v-if="communeFilter!==null" v-model="villageFilter" placeholder="Village" style="margin-right:10px; width:200px" @change="filterByVillage(value)">
          <el-option
            v-for="item in villages"
            :key="item.id"
            :label="item.nom"
            :value="item.id"
          />
        </el-select> -->
        <!-- <el-select v-if="villageFilter!==null" v-model="producteurFilter" placeholder="Producteurs">
          <el-option
            v-for="item in producteursFiltres"
            :key="item.id"
            :label="item.nom + ' ' + item.prenom"
            :value="item.id"
          />
        </el-select> -->
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">
        <!-- el-table-column align="center" label="Code" min-width="100px">
          <template slot-scope="scope">
            <span>{{ scope.row.code }}</span>
          </template>
        </el-table-column -->

        <el-table-column align="center" :label="$t('general.nom')" min-width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.nom }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('general.description')" min-width="300px">
          <template slot-scope="scope">
            <span>{{ scope.row.description }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('uniteProduction.uniteProduction')" min-width="160px">
          <template slot-scope="scope">
            <span>{{ nomUniteProduction[scope.row.unite_production_id] }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="checkPermission(['activer groupement'])" align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_active"
              @change="setGroupementStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="checkRole(['admin']) && (activeList==='corbeille' || query.keyword)" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              v-if="!scope.row.is_visible"
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setGroupementStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="checkPermission(['modifier groupement','visible groupement','supprimer groupement'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
          <template slot-scope="scope">
            <el-button-group>
              <el-button v-if="scope.row.is_visible && checkPermission(['modifier groupement'])" type="primary" size="small" icon="el-icon-edit" @click="handleEditGroupement(scope.row.id);" />
              <el-button v-if="checkPermission(['visible groupement','supprimer groupement'])" type="danger" size="small" icon="el-icon-delete" @click="handleDeleteGroupement(scope.row.id, scope.row.is_visible, scope.row.nom);" />
              <!-- <el-dropdown style="width:fit-content; padding:0px" trigger="click">
              <el-button style="padding:7px;" type="info">
                <i style="margin:auto" class="el-icon-arrow-down el-icon--right" />
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>Action 1</el-dropdown-item>
                <el-dropdown-item>Action 2</el-dropdown-item>
                <el-dropdown-item>Action 3</el-dropdown-item>
                <el-dropdown-item>Action 4</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
              <el-popover
                placement="bottom"
                title="Autres options"
                width="200"
                trigger="click"
              >
                <el-button size="small" style="margin-left:0px; margin-top:3px" @click="handleAppearAffectationDialog(scope.row)">
                  Affecter des producteurs
                </el-button>
                <el-button
                  v-if="scope.row.producteurs > 0"
                  size="small"
                  style="margin-left:0px; margin-top:3px"
                  @click="handleDisplayGroupementProducteurs(scope.row)"
                >
                  Producteurs du groupement
                </el-button>
                <el-button size="small" style="margin-left:0px; margin-top:3px" @click="showDetailsPage(scope.row.id)">
                  Détails
                </el-button>
                <el-button slot="reference" type="info" size="small" icon="el-icon-more" />
              </el-popover>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="total > query.limit" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getGroupementsList" />

      <!-- ======= REGION USER DIALOG ======= -->
      <el-dialog
        :title="editing ? $t('groupement.edit') + ' ' + currentGroupement.nom : $t('groupement.AddGroupement')"
        :visible.sync="dialogCreateGroupement"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        :loading="groupementCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="groupementForm" :rules="rules" :model="currentGroupement" label-position="left">
            <el-form-item :label="$t('uniteProduction.uniteProduction')" prop="unite_production_id">
              <el-select v-model="currentGroupement.unite_production_id" class="filter-item" :placeholder="$t('uniteProduction.uniteProduction')" style="width: 100%;" prop="unite_production_id">
                <el-option
                  v-for="(uniteProduction, idex) in uniteProductions"
                  :key="idex"
                  :label="uniteProduction.nom | uppercaseFirst"
                  :value="uniteProduction.id"
                  :disabled="!uniteProduction.is_active"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('controleur.controleur')" prop="controleur_id">
              <el-select v-model="currentGroupement.controleur_id" class="filter-item" :placeholder="$t('controleur.controleur')" style="width: 100%;" prop="controleur_id">
                <el-option
                  v-for="(controleur, index) in controleurs"
                  :key="index"
                  :label="controleur.nom + ' ' + controleur.prenom"
                  :value="controleur.id"
                  :disabled="!controleur.is_active"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('groupement.nom')" prop="nom">
              <el-input v-model="currentGroupement.nom" />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="currentGroupement.description" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateGroupement=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="groupementCreating" @click="editing ? updateGroupement() : createGroupement()">
              {{ groupementCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <el-dialog
        :title="`Affectation des producteurs au groupement ${idsProducteursAAffecter.groupement}`"
        :visible.sync="dialogCreateProducteur"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        width="30%"
      >
        <div class="content-inputs">
          <el-table
            ref="multipleTable"
            :data="producteurs"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
            />
            <el-table-column
              label="Nom"
              width="auto"
            >
              <template slot-scope="scope">{{ scope.row.nom + ' ' + scope.row.prenom }}</template>
            </el-table-column>
            <el-table-column
              property="numero"
              label="Numero"
              width="120"
            />
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateProducteur=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="producteurAffecting" @click="handleAffectProducteurs()">
              Affecter
            </el-button>
          </div>
        </span>
      </el-dialog>

      <el-dialog
        title="Producteurs appartenant au groupement"
        :visible.sync="displayGroupementsProducteur"
        width="30%"
      >
        <div class="content-inputs">
          <el-table
            ref="multipleTable"
            :data="producteursGroupement"
            style="width: 100%"
          >
            <el-table-column
              label="Nom"
              width="auto"
            >
              <template slot-scope="scope">{{ scope.row.nom + ' ' + scope.row.prenom }}</template>
            </el-table-column>
            <el-table-column
              property="numero"
              label="Numero"
              width="120"
            />
            <el-table-column label="Actions">
              <template slot-scope="scope">
                <el-button @click="navigateProducteur(scope.row.id)">Détails</el-button>
                <el-button type="danger" @click="removeProducteur(scope.row.id, scope.row.groupement_id)">Retirer</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="info" @click="displayGroupementsProducteur=false">
              {{ $t('permission.cancel') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <el-drawer
        :title="detailData[0]!== undefined ? 'Détails de la catégorie : '+detailData[0].nom:'Titre'"
        :visible.sync="detail"
        direction="rtl"
        size="40%"
      >
        <div class="demo-drawer__content">
          <el-table :data="detailData">
            <!-- el-table-column property="code" label="Code" width="150" /-->
            <el-table-column property="nom" label="Nom" width="200" />
            <el-table-column property="description" label="description" />
          </el-table>

          <div>
            <h4>Les questions de la catégorie : </h4>
          </div>
          <el-table :data="producteursGroupement">
            <el-table-column
              label="Nom"
              width="auto"
            >
              <template slot-scope="scope">{{ scope.row.nom + ' ' + scope.row.prenom }}</template>
            </el-table-column>
            <el-table-column
              property="numero"
              label="Numero"
              width="120"
            />
          </el-table>
          <div class="demo-drawer__footer" style="margin-top:'auto'">
            <!-- <el-button type="primary">Imprimer</el-button> -->
          </div>
        </div>
      </el-drawer>

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
import CountTo from 'vue-count-to';
const uniteProductionResource = new Resource('unite_productions');
const groupementResource = new Resource('groupements');
const groupementAffectationResource = new Resource('groupements_affecter_des_producteurs');
const producteurResource = new Resource('producteurs_sans_groupement');
const controleurResource = new Resource('controleurs');
const zoneResource = new Resource('zones');
const communeResource = new Resource('communes');
const villageResource = new Resource('villages');
const producteursResource = new Resource('producteurs');
export default {
  name: 'GroupementsList',
  components: { Pagination, CountTo },
  directives: { waves, permission, role },
  data() {
    return {
      uniteProductions: [],
      nomUniteProduction: [],
      list: null,
      displayGroupementsProducteur: false,
      producteursGroupement: null,
      producteurs: null,
      controleurs: null,
      idsProducteursAAffecter: {},
      dialogCreateProducteur: false,
      producteurAffecting: false,
      nomZones: [],
      nomCommunes: [],
      nomVillages: [],
      zones: null,
      communes: null,
      villages: null,
      communesBack: null,
      villagesBack: null,
      producteursFiltres: null,
      zoneFilter: null,
      communeFilter: null,
      villageFilter: null,
      producteurFilter: null,
      detail: false,
      detailData: [],
      total: 0,
      loading: true,
      activeList: 'disponibles',
      downloading: false,
      groupementCreating: false,
      editing: false,
      currentGroupement: {},
      query: {
        page: 1,
        limit: 15,
        keyword: '',
      },
      queryfiltre: {
        page: 1,
        limit: 1000,
        keyword: '',
      },
      dialogCreateGroupement: false,
      currentGroupementId: 0,
      rules: {
        nom: [{ required: true, message: this.$t('groupement.NomRequired'), trigger: 'blur' }],
        unite_production_id: [{ required: true, message: this.$t('groupement.UniteProductionRequired'), trigger: 'blur' }],
        controleur_id: [{ required: true, message: this.$t('controleur.ControleurRequired'), trigger: 'blur' }],
      },
      fileList: [],
    };
  },
  computed: {

  },
  created() {
    this.resetNewGroupement();
    this.getGroupementsList();
    this.getUnitesProductionsList();
    this.getProducteursList();
    this.getControleursList();
    this.getZonesList();
    this.getCommunesList();
    this.getVillagesList();
    this.getProducteursFiltreList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleCloseCampagneagricoleCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    showDetails(row) {
      this.producteursGroupement = row.producteurs_data;
      var newArray = [];
      console.log(row);
      newArray.push(row);
      this.detailData = newArray;
      this.detail = true;
    },
    navigateProducteur(id){
      this.$router.push({ path: '/producteurs/' + id });
    },
    removeProducteur(id, groupement_id){
      const removeResource = new Resource('producteurs/' + id + '/desaffecter-groupement');

      this.$confirm('Retirer le producteur du groupement ?', 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      })
        .then(() => {
          removeResource.store(id)
            .then(response => {
              this.$message({
                type: 'success',
                message: 'producteur retiré du groupement ',
              });
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              groupementResource.get(groupement_id).then((response) => {
                console.log('Groupements actuelle ', response.data);
                const groupement = response.data;
                this.handleDisplayGroupementProducteurs(groupement);
                this.getGroupementsList();
              });
            });
        });
    },
    cancelgroupementForm() {
      this.$refs.CampagneagricoleCreationDrawer.closeDrawer();
    },
    async setGroupementStatus(id, type){
      const data = await groupementResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    initSearch(){
      this.handleFilterGroupements();
    },
    showDetailsPage(id){
      this.$router.push({ path: '/groupement/' + id });
    },
    async filterByZone(value){
      // console.log('la zone : ', this.zoneFilter);
      this.loading = true;
      var requete = this.queryfiltre;
      this.communeFilter = null;
      this.villageFilter = null;
      // const communes = this.communesBack.filter(comm => comm.zone_id === this.zoneFilter);
      // this.communes = communes;
      requete.zone_id = this.zones[value].id;
      const { data } = await groupementResource.list(requete);
      console.log('The Data', data);
      this.list = data.reverse();
      this.total = data.length;
      this.loading = false;
      console.log('this zone id: ', this.zones[value].id);
    },
    async filterByCommune(value){
      console.log('la Commune : ', this.communeFilter);
      this.loading = true;
      var requete = this.queryfiltre;
      delete requete['zone_id'];
      this.villageFilter = null;
      // const villages = this.villagesBack.filter(village => village.commune_id === this.communeFilter);
      // this.villages = villages;
      // requete.zone_id = this.zones[this.zoneFilter].id;
      requete.commune_id = this.zones[this.zoneFilter].communes[value].id;
      const { data } = await groupementResource.list(requete);
      this.list = data;
      this.total = data.length;
      this.loading = false;
      console.log('The request ', requete);
      console.log('The length ', data.length);
    },
    async filterByVillage(value){
      console.log('le Village : ', this.villageFilter);
      this.loading = true;
      var requete = this.queryfiltre;
      // requete.zone_id = this.zoneFilter;
      // requete.commune_id = this.communeFilter;
      delete requete['commune_id'];
      requete.village_id = value;
      const { data } = await groupementResource.list(requete);
      this.list = data.reverse();
      this.loading = false;
    },
    async getGroupementsList() {
      this.loading = true;
      const { data, meta } = await groupementResource.sublist('?typeApi="all"', this.query);
      this.list = data.reverse();
      this.total = meta !== undefined ? meta.total : 0;
      console.log('===== EDI', meta.total);
      console.log(data);
      console.log('===== EVO');
      this.loading = false;
    },
    async getProducteursList() {
      const { data } = await producteurResource.list(this.query);
      this.producteurs = data;
    },
    async getControleursList() {
      const controleurquery = {
        page: 1,
        limit: 1000,
        keyword: '',
      };
      const { data } = await controleurResource.list(controleurquery);
      this.controleurs = data;
    },
    async getZonesList() {
      const { data } = await zoneResource.list(this.queryfiltre);
      this.zones = data;
      const _reg = [];
      data.forEach(zone => {
        _reg[zone.id] = zone.nom;
      });
      this.nomZones = _reg;
    },
    async getCommunesList() {
      const { data } = await communeResource.list(this.queryfiltre);
      this.communes = data;
      const _reg = [];
      data.forEach(commune => {
        _reg[commune.id] = commune.nom;
      });
      this.nomCommunes = _reg;
      this.communesBack = data;
    },
    async getVillagesList() {
      const { data } = await villageResource.list(this.queryfiltre);
      this.villages = data;
      const _reg = [];
      data.forEach(village => {
        _reg[village.id] = village.nom;
      });
      this.nomVillages = _reg;
      this.villagesBack = data;
    },
    async getProducteursFiltreList() {
      const { data } = await producteursResource.list(this.query);
      this.producteursFiltres = data;
    },
    handleSelectionChange(val){
      var remplacement = [];
      val.map(e => remplacement.push(e.id));
      this.idsProducteursAAffecter.ids = remplacement;
      console.log('SELCTION : ', this.idsProducteursAAffecter);
    },
    handleAppearAffectationDialog(group){
      this.idsProducteursAAffecter.groupement = group.nom;
      this.idsProducteursAAffecter.groupement_id = group.id;
      console.log('ICI avec : ', group.nom);
      this.dialogCreateProducteur = true;
    },
    async handleAffectProducteurs(){
      this.producteurAffecting = true;
      const donnees = {
        'producteur_ids': this.idsProducteursAAffecter.ids,
      };
      const data = await groupementAffectationResource.extra(this.idsProducteursAAffecter.groupement_id, donnees);
      if (data.success){
        this.$message({
          type: 'success',
          message: 'Producteurs affectés à ' + this.idsProducteursAAffecter.groupement + ' avec succès',
        });
        this.initSearch();
        this.idsProducteursAAffecter = {};
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
        this.idsProducteursAAffecter = {};
      }
      this.producteurAffecting = true;
      this.dialogCreateProducteur = false;
    },
    handleDisplayGroupementProducteurs(group){
      this.producteursGroupement = group.producteurs_data;
      this.displayGroupementsProducteur = true;
    },
    async getSubGroupementsList(subUrl) {
      this.loading = true;
      const { data } = await groupementResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterGroupements() {
      this.query.page = 1;
      this.getGroupementsList();
    },
    handlecreateGroupement() {
      this.editing = false;
      this.resetNewGroupement();
      this.dialogCreateGroupement = true;
      this.$nextTick(() => {
        this.$refs['groupementForm'].clearValidate();
      });
    },
    handleEditGroupement(id){
      this.editing = true;
      this.resetNewGroupement();
      this.currentGroupement = this.list.find(abonne => abonne.id === id);
      this.dialogCreateGroupement = true;
      this.$nextTick(() => {
        this.$refs['groupementForm'].clearValidate();
      });
    },
    handleDeleteGroupement(id, visible, nom) {
      console.log('id : ' + id + 'nom : ' + nom);
      this.$confirm(this.$t('groupement.deleteWarn') + ' ' + nom + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        console.log('result : ');
        if (!visible && (checkRole(['admin']) || checkPermission(['supprimer groupement']))) {
          console.log('result 2 : ');
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              groupementResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('groupement.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setGroupementStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createGroupement() {
      this.$refs['groupementForm'].validate((valid) => {
        if (valid) {
          this.groupementCreating = true;
          groupementResource
            .store(this.currentGroupement)
            .then(async(response) => {
              this.$message({
                message: this.$t('groupement.NewGroupement') + ' ' + this.currentGroupement.nom + ' ' + this.$t('groupement.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentGroupementId = response.data.id;
              this.resetNewGroupement();
              this.dialogCreateGroupement = false;
              this.handleFilterGroupements();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.groupementCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    updateGroupement() {
      this.$refs['groupementForm'].validate((valid) => {
        if (valid) {
          this.groupementCreating = true;
          groupementResource.update(this.currentGroupement.id, this.currentGroupement).then(response => {
            this.resetNewGroupement();
            this.handleFilterGroupements();
            this.$message({
              message: this.$t('groupement.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateGroupement = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.groupementCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    resetNewGroupement() {
      this.currentGroupement = {
        nom: '',
        description: '',
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('groupement.nom'), this.$t('general.description')];
        const filterVal = ['id', 'nom', 'description'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des groupements SCIAGRI_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onChange(file, fileList){
      var a = this.fileList;
      a.push({ name: file.name, url: file.url });
      // console.log('onChange begin');
      this.fileList = a;
      // console.log(this.fileList);
      // console.log('onChange end');
    },
    async getUnitesProductionsList() {
      const { data } = await uniteProductionResource.sublist('?typeApi="all"', this.query);
      this.uniteProductions = data;
      const _reg = [];
      data.forEach(uniteProduction => {
        _reg[uniteProduction.id] = uniteProduction.nom;
      });
      this.nomUniteProduction = _reg;
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-people {
        background: #40c9c6;
      }

      .icon-message {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-shopping {
        background: #34bfa3
      }
    }

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width:550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
